import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col, Form, Button } from 'reactstrap';

import { useToggle } from '../../hooks';
import SettingsDropdown from './SettingsDropdown';
import {
  SerialNumberFilter,
  OffSetFilter,
  SvcpnFilter,
  MachineShipDateFilter,
  ProjectFilter,
  StatusFilter,
  ShippedToCustomerNameFiler,
  SoldToCustomerNameFiler,
  ShipCountryFilter,
  ReferenceFiler,
  SalesOrderNumberFilter,
  RangeOrderDateFilter,
  UidFilter,
  GeographyFilter,
  ProductFilter,
  ShowCanceledFilter,
} from './Filters';

const FilterButton = styled(Button)`
  height: 38px;
  border-radius: 5px;
`;

const ShowCanceledFilterWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: -22px;
`;

const RecordsFilter = ({
  applyFilter,
  makeSetFilter,
  availableFilters,
  filters,
  clearFilter,
  switchableColumns,
  setSwitchableColumns,
}) => {
  const [isSettingsShown, toggleSettingsShown] = useToggle();

  return (
    <>
      <Form className="align-items-center">
        <Row xs={12} className={'mt-3'}>
          <Col className="filter-multi-search-wrapper">
            <SalesOrderNumberFilter
              filters={filters}
              makeSetFilter={makeSetFilter}
            />
          </Col>
        </Row>
      </Form>
      <Form className="align-items-center">
        <Row xs={12} className={'mt-3'}>
          <Col className="filter-multi-search-wrapper">
            <SerialNumberFilter
              filters={filters}
              makeSetFilter={makeSetFilter}
            />
          </Col>
        </Row>
      </Form>
      <Form className="align-items-center">
        <Row xs={12} className="mt-3 filter-multi-search-wrapper">
          <Col className="filter-multi-search-wrapper">
            <UidFilter filters={filters} makeSetFilter={makeSetFilter} />
          </Col>
        </Row>
      </Form>
      <Row>
        <Form className="align-items-center">
          <Row className="mt-3">
            <Col xs={2}>
              <ShippedToCustomerNameFiler
                filters={filters}
                makeSetFilter={makeSetFilter}
              />
            </Col>
            <Col className="mb-3" xs={2}>
              <GeographyFilter
                availableFilters={availableFilters}
                filters={filters}
                makeSetFilter={makeSetFilter}
              />
            </Col>
            <Col xs={2}>
              <ShipCountryFilter
                availableFilters={availableFilters}
                filters={filters}
                makeSetFilter={makeSetFilter}
              />
            </Col>
            <Col xs={2}>
              <OffSetFilter makeSetFilter={makeSetFilter} filters={filters} />
            </Col>
            <Col xs={2}>
              <StatusFilter
                availableFilters={availableFilters}
                makeSetFilter={makeSetFilter}
                filters={filters}
              />
            </Col>
            <Col xs={2}>
              <SoldToCustomerNameFiler
                filters={filters}
                makeSetFilter={makeSetFilter}
              />
            </Col>
          </Row>
        </Form>
      </Row>
      <Row className="mb-3">
        <Form className="align-items-center">
          <Row>
            <Col xs={2}>
              <ProductFilter
                availableFilters={availableFilters}
                filters={filters}
                makeSetFilter={makeSetFilter}
              />
            </Col>
            <Col xs={2}>
              <SvcpnFilter
                availableFilters={availableFilters}
                makeSetFilter={makeSetFilter}
                filters={filters}
              />
            </Col>
            <Col xs={2}>
              <RangeOrderDateFilter
                makeSetFilter={makeSetFilter}
                filters={filters}
              />
            </Col>
            <Col xs={2}>
              <MachineShipDateFilter
                makeSetFilter={makeSetFilter}
                filters={filters}
              />
            </Col>
            <Col xs={2}>
              <ProjectFilter
                availableFilters={availableFilters}
                makeSetFilter={makeSetFilter}
                filters={filters}
              />
            </Col>
            <Col xs={2}>
              <ReferenceFiler
                availableFilters={availableFilters}
                makeSetFilter={makeSetFilter}
                filters={filters}
              />
            </Col>
            <Col xs={2}>
              <ShowCanceledFilterWrapper>
                <ShowCanceledFilter
                  makeSetFilter={makeSetFilter}
                  filters={filters}
                />
              </ShowCanceledFilterWrapper>
            </Col>
          </Row>
          <Row className="mt-3 d-flex justify-content-end">
            <Col xs={3} className="d-flex justify-content-end">
              <FilterButton
                color="primary"
                outline
                onClick={clearFilter}
                className="h-auto"
                style={{ width: '120px' }}
              >
                <span>Clear filter</span>
              </FilterButton>
              <FilterButton
                color="primary"
                onClick={applyFilter}
                className="ml-4"
              >
                <span>Apply</span>
              </FilterButton>
            </Col>
          </Row>
          <Row>
            <Col xs="auto">
              <SettingsDropdown
                isSettingsShown={isSettingsShown}
                onClick={toggleSettingsShown}
                switchableColumns={switchableColumns}
                setSwitchableColumns={setSwitchableColumns}
              />
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
};

RecordsFilter.propTypes = {
  filters: PropTypes.object.isRequired,
  availableFilters: PropTypes.object.isRequired,
  makeSetFilter: PropTypes.func.isRequired,
  applyFilter: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  switchableColumns: PropTypes.array.isRequired,
  setSwitchableColumns: PropTypes.func.isRequired,
  isSettingsEnabled: PropTypes.bool.isRequired,
};

export default RecordsFilter;
